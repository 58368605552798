import { Button } from "./Button";
import { Container } from "./Container";
import { FadeIn } from "./FadeIn";
import { Offices } from "./Offices";

export function ContactSection() {
  return (
    <Container className="mt-24 sm:mt-32 lg:mt-40">
      <FadeIn className="-mx-6 rounded-4xl bg-light-beige px-6 py-20 sm:mx-0 sm:py-32 md:px-12">
        <div className="mx-auto max-w-4xl">
          <div>
            <h2 className="font-display text-3xl font-medium text-black [text-wrap:balance] sm:text-4xl">
      
            Blv “Bajram Curri”, Ura e Nisharakut, 
            <br />
            prane ish stacionit te fundit te Tiranes se Re
  
            </h2>
     
            <div className="mt-10 border-t border-white/10 pt-10">
              <h3 className="font-display  font-semibold text-black">
                Our offices
              </h3>
              <Offices
                invert
                className="mt-6 grid text-black grid-cols-1 gap-8 sm:grid-cols-2"
              />
            </div>
          </div>
        </div>
      </FadeIn>
    </Container>
  );
}
