import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RootLayout } from "./RootLayout";

import Home from "./pages/Home";
import Work from "./pages/Work";

function App() {
  return (
    <Router>
      <RootLayout>
        {" "}
        {/* Wrap your Routes with the RootLayout */}
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </RootLayout>
    </Router>
  );
}

export default App;
