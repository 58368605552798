import React from "react";
import { SectionIntro } from "../components/SectionIntro";
import { Container } from "../components/Container";
import { FadeIn } from "../components/FadeIn";
import { List, ListItem } from "../components/List";
// import {    loadCaseStudies } from '../lib/mdx'
import { ContactSection } from "../components/ContactSession";
import { Testimonial } from "../components/Testimonial";
import { StylizedImage } from "../components/StylizedImage";
import "../styles/base.css";
import korridori from "../images/korridori.jpg";
import innovaDentalLogo from "../images/IMG-7570.jpg";
// import { caseStudies } from "../data/Sherbimet";
// const clients = [
//   ["Phobia", "logoPhobiaLight.png"],
//   ["Family Fund", "logoFamilyFund.png"],
//   ["Unseal", "logoUnseal.png"],
//   ["Mail Smirk", "logoMailSmirk.png"],
//   ["Home Work", "logoHomeWork.png"],
//   ["Green Life", "logoGreenLife.png"],
//   ["Bright Path", "logoBrightPath.png"],
//   ["North Adventures", "logoNorthAdventures.png"],
// ];

function Clients() {
  return (
    <div className="background-recepsion mt-24 w-full rounded-4xl py-20 sm:mt-32 sm:py-32">
      {/* Render your client logos here */}
    </div>
  );
}

type CaseStudy = {
  client: string;
  title: string;
  description: string;
  summary: string[];
};

export function CaseStudies({
  caseStudies,
}: {
  caseStudies: Array<CaseStudy>;
}) {
  return (
    <div className="mt-24 text-dark-brown sm:mt-32 lg:mt-40">
      <SectionIntro
        title="Zgjedhja Juaj për nje Buzëqeshje te Shëndetshme"
        className="mt-24 text-dark-brown sm:mt-32 lg:mt-40">
        <p>
          Në Innova Dental, ne kemi përqendruar energjitë tona në ofrimin e
          trajtimit të më të mirë për gojën tuaj. Shërbimet tona dentare janë të
          ndara në tri kategori kryesore për të plotësuar nevojat tuaja:
        </p>
      </SectionIntro>
      <div className="mt-16">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {caseStudies.map((caseStudy, index) => (
            <div key={index} className="flex">
              <article className="hover:bg-neutral-50 relative flex w-full flex-col rounded-3xl p-6 ring-1 ring-silver transition sm:p-8">
                <h3>
                  <a href="#sherbimet">
                    <span className="absolute inset-0 rounded-3xl" />
                    <img alt={caseStudy.client} className="h-16 w-16" />
                  </a>
                </h3>
                <p className="text-dark-brown mt-6 font-display text-2xl font-semibold">
                  {caseStudy.title}
                </p>
                <p className="text-neutral-600 mt-4 text-base">
                  {caseStudy.description}
                </p>
              </article>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Services() {
  return (
    <div id="sherbimet">
      <SectionIntro
        eyebrow="Sherbimet"
        title="Ne ofrojmë një gamë të gjërë të shërbimeve dentare për të plotësuar nevojat tuaja."
        className="mt-24 sm:mt-32 lg:mt-40">
        <p>
          Për ne, çdo pacient është i veçantë dhe kemi krijuar shërbime të
          personalizuara për të siguruar kujdesin më të mirë dental.
        </p>
      </SectionIntro>
      <Container className="mt-16">
        <div className="lg:flex lg:items-center lg:justify-end">
          <div className="flex justify-center lg:w-1/2 lg:justify-end lg:pr-12">
            <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
              <StylizedImage
                src={innovaDentalLogo}
                sizes="(min-width: 1024px) 41rem, 31rem"
                className="justify-center lg:justify-end"
                shape={0 as unknown as undefined}
              />
            </FadeIn>
          </div>
          <List className="mt-2 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4">
            <ListItem title="Kirurgji orale dhe Implantologji e avancuar">
              Trajtimi kirurgjikal i patologjive te ndryshme ne goje dhe
              zevendesimi i mungesave dentare me ane te implanteve. Implantet qe
              ofrojme jane te certifikuara dhe te pranishme mbi 30 vjet ne treg.
              Cdo pacient pajiset me certifikate implantare e njohur
              nderkombetarisht!
            </ListItem>
            <ListItem title="Protetike Estetike">
              Konsiston ne planifikimin dhe rehabilitimin funksional dhe
              estetike te dhembeve me probleme te ngjyres, ne raste frakturash
              dentare apo pacienti ka pakenaqesi ne aspektin estetik te
              buzeqeshjes. Faseta Emax( Venneers) , apo kurora Zirconi
            </ListItem>
            <ListItem title="Ortodonci">
              Specialiteti qe merret me problematikat dhe zgjidhjen e
              keqkafshimeve si tek femijet edhe te rriturit. Terapia realizohet
              me aparate tradicionale(telat) dhe aparatet estetike, te
              padukshme(maskerinat) transparente.
            </ListItem>
            <ListItem title="Periodontologji">
              Fusha qe diagnostikon dhe trajton semudjet e mishrave dhe kockes
              perreth dhembeve.Trajtime specifike dhe kirurgjia miniinvazive
              mundesojne jetegjatesine, por edhe rikuperimin e shendetit oral te
              keta paciente.
            </ListItem>
            {/* <ListItem title="Terapi dhe Endodonci">
              Trajtimi dhe kurimi i elementeve dentare duke filluar nga kariesi, terapia kanalare(trajtimi i rrenjeve) dhe mjekimi i infeksioneve ne goje
              </ListItem>
              <ListItem title="Pedodonti">
              Trajtimi i dhembeve te qumeshtit dhe problematikave orale tek femijet kane rendesi te vecante tek shendeti ne pergjithesi.Kontrollet periodike dhe trajtimet e nevojshme: mbushje, fluorizime apo Silantet sigurojne mbarevajtjen e procesit te daljes se dhembeve te perhershem por edhe edukimin mbi kujdesin oral!
              </ListItem> */}
          </List>
        </div>
      </Container>
    </div>
  );
}

function Home() {
  // let caseStudies = (await loadCaseStudies()).slice(0, 3)

  return (
    <>
      <Container className="mt-24 sm:mt-32 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="text-neutral-950 font-display text-5xl font-medium tracking-tight [text-wrap:balance] sm:text-7xl">
            Përqafo Buzëqeshjen Tënde
          </h1>
          <p className="text-neutral-600 mt-6 text-xl">
            Mirësevini në ambientin ku buzëqeshja juaj është prioriteti ynë. Ne
            punojmë për të ofruar një shërbim të shkëlqyeshëm dhe për të
            përmirësuar shëndetin tuaj oral në çdo vizitë tuaj.
          </p>
        </FadeIn>
      </Container>

      <Clients />

      <CaseStudies caseStudies={[]} />

      <Testimonial
        className="mt-24 sm:mt-32 lg:mt-40 korridori"
        client={{ name: "Phobia", logo: `${korridori}` }}>
        Ne besojmë në qëllimin tonë për të sjellë një buzëqeshje më të
        shëndetshme dhe më të lumtur për çdo pacient.
      </Testimonial>

      <Services />

      <ContactSection />
    </>
  );
}

export default Home;
