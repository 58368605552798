import clsx from 'clsx';
import { Container } from './Container';
import { GridPattern } from './GridPattern'; // Include GridPattern
import { FadeIn } from './FadeIn'; // Include FadeIn

interface TestimonialProps {
  children: React.ReactNode;
  client: { logo: string; name: string }; // Replace 'ImageProps' with 'string' for the 'logo' property
  className?: string;
}

export function Testimonial({ children, client, className }: TestimonialProps) {
  return (
    <div
      className={clsx(
        'relative isolate bg-neutral-50 py-16 sm:py-28 md:py-32 testimonial',
        className
      )}
    >
      <GridPattern
        className="absolute inset-0 -z-10 h-full w-full fill-light-base stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_50%,transparent_60%)]"
        yOffset={-256}
      />
      <Container>
        <FadeIn>
          <div className="max-w-7xl mx-auto">
            <blockquote className="relative font-display text-3xl font-medium tracking-tight text-neutral-950 sm:text-4xl">
              <p className="before:content-['“'] after:content-['”'] sm:before:absolute sm:before:right-full">
                {children}
              </p>
            </blockquote>
            <figcaption className="mt-10">
              <img src={client.logo} alt={client.name} /> {/* Use the 'img' tag */}
            </figcaption>
          </div>
        </FadeIn>
      </Container>
    </div>
  );
}
