import clsx from 'clsx';

type ButtonProps = {
  invert?: boolean;
} & (
  | React.ComponentPropsWithoutRef<'a'>
  | (React.ComponentPropsWithoutRef<'button'> & { href?: undefined })
);

export function Button({
  invert = false,
  className,
  children,
  ...props
}: ButtonProps) {
  className = clsx(
    className,
    'inline-flex rounded-full px-4 py-1.5 text-sm font-semibold transition',
    invert
      ? 'bg-light-beige text-neutral-950 hover:bg-neutral-200'
      : 'bg-dark-brown text-light-beige hover:bg-neutral-800'
  );

  let inner = <span className="relative top-px">{children}</span>;

  if (typeof props.href === 'undefined') {
    return (
      <button className={className} {...(props as React.ComponentPropsWithoutRef<'button'>)}>
        {inner}
      </button>
    );
  }

  return (
    <a className={className} href={props.href}>
      {inner}
    </a>
  );
}
