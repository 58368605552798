import clsx from "clsx";

function Office({
  name,
  children,
  invert = false,
}: {
  name: string;
  children: React.ReactNode;
  invert?: boolean;
}) {
  return (
    <address className={clsx("text-sm not-italic")}>
      <strong>{name}</strong>
      <br />
      {children}
    </address>
  );
}

export function Offices({
  invert = false,
  ...props
}: React.ComponentPropsWithoutRef<"ul"> & { invert?: boolean }) {
  return (
    <ul {...props} className="flex flex-col">
      <li className="w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3753.405394110398!2d19.79623067674842!3d41.32013887130851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031000690d7ab%3A0xdcb6184b9a05ec59!2sInnova%20Dental%20Clinic!5e1!3m2!1ssq!2s!4v1738415388272!5m2!1ssq!2s"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
        ></iframe>
      </li>
    </ul>
  );

}
